<template>
  <a-modal
      v-model="visible"
      centered
      :footer="null"
      :bodyStyle="bodyStyle"
      class="login-modal"
      :maskClosable="false"
      width="440"
      :destroyOnClose="true">
    <div class="wrapper">
      <p>微信扫码一键登录/注册</p>
      <a-spin :spinning="!qrcode">
        <div class="qrcode-temp">
          <img v-if="qrcode" :src="qrcode" class="qrcode-temp"/>
        </div>
      </a-spin>

      <p>使用微信扫码-关注公众号登录/注册</p>
      <!--      <a-input v-model="phoneNum" placeholder="请输入手机号"/>-->
      <!--      <div class="code-wrapper">-->
      <!--        <a-input v-model="code" placeholder="请输入验证码"/>-->
      <!--        <a-button primay style="margin-left: 15px;" :loading="loading" @click="findCode">-->
      <!--          <span>{{ !loading ? '获取验证码' : num + 's' }}</span>-->

      <!--        </a-button>-->
      <!--      </div>-->
      <!--      <a-button type="primary" @click="submit">登录账户</a-button>-->
      <!--      <a-divider style="margin-top: 65px; font-weight: normal;color: #8C8C8C;font-size: 14px;">其他方式</a-divider>-->
      <!--      <icon-font type="icon-wechat" class="icon" style="font-size: 27px;margin-top: 15px;"/>-->
    </div>
  </a-modal>
</template>
<script>

import {getLoginQrCode, queryUser, verificationCode} from "@/api/login";

export default {
  name: "MoveModal",
  props: {
    modalVisible: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    visible: {
      get() {
        return this.modalVisible
      },
      set(newVal) {
        this.$emit('update:modalVisible', newVal)
      }
    }
  },
  data() {
    return {
      phoneNum: '',
      code: '',
      loading: false,
      num: 60,
      bodyStyle: {
        padding: '55px 60px 20px 60px',
      },
      timer: null,
      qrcode: '',
      userId: ''
    }
  },
  methods: {
    findQrcode() {
      getLoginQrCode().then(res => {
        if (res.success) {
          this.qrcode = res.data.url
          this.userId = res.data.uid
        }
      })
    },
    query() {
      this.timer = setInterval(() => {
        queryUser(this.userId).then(res => {
          if (res && res.data && res.data.id) {
            this.$store.dispatch("user/setUser", res.data)
            this.visible = false
            clearInterval(this.timer)
            this.$emit('loginSuccessCallback')
            // location.reload();
          }
        })
      }, 1500)
    },
    // findCode() {
    //   this.countdown()
    //   verificationCode(this.phoneNum).then(res => {
    //     console.log("====>", res)
    //   })
    // },
    // countdown() {
    //   this.loading = true
    //   this.num = 60
    //   this.timer = setInterval(() => {
    //     this.num--;
    //     if (this.num <= 0) {
    //       this.loading = false
    //     }
    //   }, 1000)
    // },
    submit() {
      const params = {
        phoneNum: this.phoneNum,
        code: this.code,
        loginType: 1
      }
      this.$store.dispatch('user/login', params).then((res) => {
        console.log(res)
      })

      // login(params).then(res => {
      //   if (!res.success) {
      //     this.$message.error(res.message)
      //   } else {
      //     let data = res.data
      //     setToken(data.token)
      //     console.log(this.$store.mutations)
      //     // this.$store.getters
      //   }
      // })
    }
  },
  mounted() {
    this.findQrcode()
    this.query()
    this.$once('hook:beforeDestroy', () => {
      if (this.timer) {
        clearInterval(this.timer) // 此处的timer即 上文const的 timer
      }
    })
  }
}
</script>
<style scoped lang="scss">
.login-modal {
  p {
    position: relative;
    top: -15px;
    text-align: center;
    font-size: 16px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 280px;


    .qrcode-temp {
      width: 280px;
      height: 280px;
      background: #efefef;
    }

    p:first-child {
      font-size: 19px;
    }

    p:last-child {
      font-size: 15px;
      margin-top: 25px;
    }

    .code-wrapper {
      display: flex;

    }
  }
}

.ant-input {
  border-radius: 0 !important;
  margin-top: 25px;
}

.ant-btn {
  border-radius: 0 !important;
  margin-top: 25px;
}
</style>